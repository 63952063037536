import React from "react"
import { Link } from "gatsby"


const CTAButton = (props) => (
    <Link to={props.url}>
        <div className="cta-button">
            <span className="cta-circle" aria-hidden="true">
                <span className="icon arrow"></span>
            </span>
            <span className="cta-text">{props.children}</span>
        </div>
    </Link>
)

export default CTAButton